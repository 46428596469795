<template>
  <div style="color: #000;height: 100%;background: #f5f5f5;">
    <van-nav-bar title="收银台" left-arrow :fixed="true" :z-index="999" @click-left="back"></van-nav-bar>
    <div style="padding-top:46px;background: #f5f5f5;">
      <div class="baiabi">
        <div class="yinfu">剩余应付</div>
        <div class="nime">
          <span class="jins">￥</span>
          <span v-if="isok">{{huiyuan.activityPrice.toFixed(2)}}</span>
          <span v-else>{{xiaofei.amountActual.toFixed(2)}}</span>
        </div>
      </div>
      <div class="fangs">请选择付款方式</div>
      <van-radio-group v-model="radio">
        <van-cell-group>
          <van-cell title="微信支付" clickable @click="radio = '1'" :icon="weixin">
            <template #right-icon>
              <van-radio name="1" checked-color="#07c160" icon-size="18px" />
            </template>
          </van-cell>
          <!-- @click="radio = '3'" -->
          <!-- <van-cell title="支付宝" clickable :icon="zhifubao">
            <template #right-icon>
              <van-radio name="3" disabled checked-color="#07c160" icon-size="18px" />
            </template>
          </van-cell> -->
          <!-- <van-cell title="储值卡" clickable @click="radio = '2'" :icon="chuzhika">
            <template #right-icon>
              <van-radio name="2" checked-color="#07c160" icon-size="18px" />
            </template>
          </van-cell> -->
        </van-cell-group>
      </van-radio-group>
      <div class="bott">
        <div class="bott_c" v-preventReClick @click="zhifu">确认支付</div>
      </div>
    </div>
  </div>
</template>

<script>
import userModel from "../../api/user";
import orderModel from "../../api/order";
import preventReClick from "../../assets/preventReClick";
// import preventBack from "vue-prevent-browser-back"; //组件内单独引入
export default {
  components: {},
  // mixins: [preventBack], //注入
  data() {
    return {
      huiyuan: JSON.parse(sessionStorage.getItem("huiyuan")),
      xiaofei: JSON.parse(localStorage.getItem("xiaofei")),
      chexin: JSON.parse(sessionStorage.getItem("chexin")),
      shangjia: JSON.parse(sessionStorage.getItem("shangjia")),
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      list: [],
      radio: "1",
      loading: false,
      ids:'',
      isWxMini: "",
      weixin: require("../../assets/img/weixin.png"),
      zhifubao: require("../../assets/img/zhifubao.png"),
      chuzhika: require("../../assets/img/chuzhika.png"),
      total: 0,
      shareId: this.$route.query.shareId,
      isok: this.$route.query.isok,
      cid: this.$route.query.cid,
      finished: false,
    };
  },
  methods: {
    zhifu() {
      var _this = this;
      //isok = true 是套餐 ，false 是 维修单结算 type:0 jsapi支付 1:二维码支付
      var province = this.shangjia.province
        ? this.shangjia.province.toString()
        : "";
      var city = this.shangjia.city ? this.shangjia.city.toString() : "";
      var district = this.shangjia.district
        ? this.shangjia.district.toString()
        : "";
      var address = this.shangjia.address
        ? this.shangjia.address.toString()
        : "";
      var areaDetail = province + city + district + address;
      if (this.isok) {
        var lis = {
          id: this.huiyuan.id,
          activityPrice: this.huiyuan.activityPrice,
          // activityUrl: this.huiyuan.activityUrl,
           activityUrl:'',
          customerId: this.userInfo ? this.userInfo.customerId : "",
          gid: this.shangjia.id,
          payType: this.radio,
          type: this.huiyuan.type,
          shareId: this.shareId ? this.shareId : null,
          name: this.huiyuan.name,
          areaDetail: areaDetail, //维修厂详细地址
          carModel: this.chexin ? this.chexin.carBrand : "", //车型
          carNo: this.chexin ? this.chexin.carNo : "", //车牌
          shareId: this.$route.query.cid ? this.$route.query.cid : 0, //分享者的id
          shareFlag: 1, //分享提成标志 0:不是 1:是
          garageName: this.shangjia.name, //修理厂名称
          positionJd: this.shangjia.positionJd,
          positionWd: this.shangjia.positionWd,
          tel: this.shangjia.legalPersonPhone,
          receiver: this.shangjia.legalPerson, //用户名称
        };
        var isWxMini = window.__wxjs_environment === "miniprogram";
        console.log(lis);
        if (isWxMini) {
          if (_this.radio == "2") {
            orderModel.goumaivip(lis).then((res) => {
              if (res.code == 200) {
                _this.$toast("购买成功！");
                setTimeout(() => {
                  _this.$router.go(-1);
                }, 800);
              }
            });
          } else {
            lis.token = localStorage.getItem("Authorization");
            lis.ccisok = 4; //3:会员卡订单 4:套餐订单 5:充值卡订单
            let payDataStr = JSON.stringify(lis);
            wx.miniProgram.navigateTo({
              url: "/pages/pay/pay?payDataStr=" + payDataStr, // 微信小程序调用支付的页面
            });
          }
        } else {
          orderModel.goumaivip(lis).then((res) => {
            if (res.code == 200) {
              this.ids = res.data.id;
              if (this.radio == "1") {
                if (typeof WeixinJSBridge === "undefined") {
                  // 微信浏览器内置对象。参考微信官方文档
                  if (document.addEventListener) {
                    document.addEventListener(
                      "WeixinJSBridgeReady",
                      _this.onBridgeReady(res.data),
                      false
                    );
                  } else if (document.attachEvent) {
                    document.attachEvent(
                      "WeixinJSBridgeReady",
                      _this.onBridgeReady(res.data)
                    );
                    document.attachEvent(
                      "onWeixinJSBridgeReady",
                      _this.onBridgeReady(res.data)
                    );
                  }
                } else {
                  console.log("准备调用微信支付");
                  _this.onBridgeReady(res.data);
                }
              } else if (this.radio == "2") {
                this.$toast("购买成功！");
                setTimeout(() => {
                  window.location.href =
                    "/order_details?id=" +
                    res.data +
                    "&status=" +
                    0 +
                    "&row=" +
                    0 +
                    "&type=" +
                    4 +
                    "&rowtype=" +
                    0;
                }, 800);
              }
            }
          });
        }
      } else {
        var lis = {
          billNumber: this.xiaofei.billNumber,
          oid: this.xiaofei.id,
          balance: 0,
          payType: this.radio,
          totalMoney: this.xiaofei.amountActual,
          gid: this.xiaofei.gid,
          type: 0,
        };
        var isWxMini = window.__wxjs_environment === "miniprogram";
        console.log(isWxMini);
        if (isWxMini) {
          if (_this.radio == "2") {
            orderModel.weixiupay(lis).then((res) => {
              if (res.code == 200) {
                _this.$toast("购买成功！");
                setTimeout(() => {
                  _this.$router.go(-1);
                }, 800);
              }
            });
          } else {
            lis.token = localStorage.getItem("Authorization");
            // lis.ccisok = 4; //3:会员卡订单 4:套餐订单 5:充值卡订单
            let payDataStr = JSON.stringify(lis);
            wx.miniProgram.navigateTo({
              url: "/pages/paydindan/paydindan?payDataStr=" + payDataStr, // 微信小程序调用支付的页面
            });
          }
        } else {
          orderModel.weixiupay(lis).then((res) => {
            if (res.code == 200) {
              if (this.radio == "1") {
                if (typeof WeixinJSBridge === "undefined") {
                  // 微信浏览器内置对象。参考微信官方文档
                  if (document.addEventListener) {
                    document.addEventListener(
                      "WeixinJSBridgeReady",
                      _this.onBridgeReady(res.data),
                      false
                    );
                  } else if (document.attachEvent) {
                    document.attachEvent(
                      "WeixinJSBridgeReady",
                      _this.onBridgeReady(res.data)
                    );
                    document.attachEvent(
                      "onWeixinJSBridgeReady",
                      _this.onBridgeReady(res.data)
                    );
                  }
                } else {
                  console.log("准备调用微信支付");
                  _this.onBridgeReady(res.data);
                }
              } else if (this.radio == "2") {
                this.$toast.success("购买成功！");
                setTimeout(() => {
                  this.$router.go(-1);
                }, 1000);
              }
            }
          });
        }
      }
    },
    onBridgeReady(data) {
      console.log("调用微信支付WeixinJSBridge");
      var vm = this;
      WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          // 下面参数内容都是后台返回的
          appId: data.appId, // 公众号名称，由商户传入
          timeStamp: data.timeStamp, // 时间戳
          nonceStr: data.nonceStr, // 随机串
          package: data.package, // 预支付id
          signType: data.signType, // 微信签名方式
          paySign: data.paySign, // 微信签名
        },
        function (res) {
          // 使用以上方式判断前端返回,微信团队郑重提示：res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
          if (res.err_msg === "get_brand_wcpay_request:ok") {
            vm.$toast.success("支付成功");
            setTimeout(() => {
              // vm.$router.go(-1);
               
                  window.location.href =
                    "/order_details?id=" +
                    vm.ids +
                    "&status=" +
                    0 +
                    "&row=" +
                    0 +
                    "&type=" +
                    4 +
                    "&rowtype=" +
                    0;
            }, 1000);
          } else {
            vm.$toast("支付失败");
          }
        }
      );
    },
    onLoad() {
      // const loading = this.$toast.loading({
      //   mask: true,
      //   duration: 0,
      //   message: "加载中..."
      // });
      // const data = {
      //   gid: this.shangjia.id
      // };
      // orderModel
      //   .taocanlist(data)
      //   .then(e => {
      //     loading.clear();
      //     this.list = e.data;
      //   })
      //   .catch(() => loading.clear());
    },
    onLoads() {},
    // pushHistory() {
    //   //写入空白历史路径
    //   let state = {
    //     title: "title",
    //     url: "#"
    //   };
    //   window.history.pushState(state, state.title, state.url);
    // },

    // backziding() {
    //   this.pushHistory();
    //   window.addEventListener(
    //     "popstate",
    //     function(e) {
    //       location.href = "/user";
    //     },
    //     false
    //   );
    // },
    back() {
      this.$router.go(-1);
      // this.$router.push({
      //   path: "user"
      // });f
    },
  },
  created() {
    // this.onLoad();
    var isWxMini = window.__wxjs_environment === "miniprogram";
    this.isWxMini = isWxMini;
  },
  mounted() {
    // this.backziding();
  },
};
</script>

<style lang="less" scoped>
.fangs {
  padding: 18px 12px 7px 12px;

  font-size: 14px;
  color: #666;
}
.van-icon__image {
  width: 20px;
  height: 20px;
  margin-top: 3px;
}
.yuer {
  background: #fff;
  margin-top: 15px;
  border-radius: 15px 15px 0 0;
  padding: 20px 20px 0px 20px;
  height: 100%;
  // padding: 20px 15px;
  font-size: 14px;
  margin-top: 15px;
}
.inptt {
  padding: 15px 0;
  font-size: 26px;
  margin: 0 20px;
  background: #fff;
  border-bottom: 1px solid #f5f5f5;
}
.baiabi {
  padding: 10px 0 15px 0;
  background: #fff;
  // height:70px ;
  text-align: center;
}
.jins {
  font-size: 14px;
}
.nime {
  color: red;
  margin-top: 8px;
}
.yinfu {
  font-size: 12px;
  color: #666;
}
// 底部
.bott {
  height: 55px;
  width: 100%;
  background: #fff;
  position: fixed;
  bottom: 0;
  padding: 0 12px;
}
.bott_c {
  margin-top: 10px;
  font-size: 14px;
  background: #df3447;
  color: #fff;
  text-align: center;
  font-weight: bold;
  padding: 7px 18px;
  border-radius: 19px;
}
</style>