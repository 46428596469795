import request from '@/libs/request'
const user = {
  auth(params) {
    return request({
      url: '/vehicle/diy/mall/login',
      method: 'get',
      params
    })
  },
  list(params) {//轮胎列表
    return request({
      url: '/vehicle/diy/mall/classify/list',
      method: 'get',
      params
    })
  },
  config(params) {
    return request({
      url: '/vehicle/wxapi/config',
      method: 'get',
      params
    })
  },
  details(params) {//轮胎列表详情
    return request({
      url: '/vehicle/diy/mall/classify/details',
      method: 'get',
      params
    })
  },
  vincarModel(params) {//车架号识别车型
    return request({
      url: '/vehicle/diy/mall/vin/carModel',
      method: 'get',
      params
    })
  },
  vintires(params) {//查询车辆轮胎规格列表
    return request({
      url: '/vehicle/diy/mall/get/tires',
      method: 'get',
      params
    })
  },
  penqiprice(params) {//喷漆价格
    return request({
      url: '/vehicle/diy/mall/painting/price',
      method: 'get',
      params
    })
  },
  getOrderLogNew(params) {//查询工单跟踪记录(新)
    return request({
      url: '/vehicle/order/getOrderLogNew',
      method: 'get',
      params
    })
  },
  miaoshadetails(params) {//轮胎列表详情
    return request({
      url: '/vehicle/diy/mall/seckill/details',
      method: 'get',
      params
    })
  },
  count(params) {//查询保养数量
    return request({
      url: '/vehicle/diy/mall/maintain/count',
      method: 'get',
      params
    })
  },
  yanhulist(params) {//查询保养数量
    return request({
      url: '/vehicle/diy/mall/maintain/list',
      method: 'get',
      params
    })
  },
  leimulist(params) {//查询类目下的商品
    return request({
      url: '/vehicle/diy/mall/classify/list',
      method: 'get',
      params
    })
  },
  unfinishList(params) {//检查车辆是否有未结算的订单(工单)
    return request({
      url: '/vehicle/order/checkOrder/unfinishList',
      method: 'get',
      params
    })
  },

  genhuanlist(params) {//更换产品
    return request({
      url: '/vehicle/diy/mall/change/produce/new',
      method: 'get',
      params
    })
  },
  xichelist(params) {//查询洗车类目下的商品
    return request({
      url: '/vehicle/diy/mall/beauty/list',
      method: 'get',
      params
    })
  },
  partBrand(params) {//根据配件种类查询品牌
    return request({
      url: '/vehicle/system/supplier/get/partBrand',
      method: 'get',
      params
    })
  },
  chepinpailist(params) {//查询所有车品牌
    return request({
      url: '/vehicle/config/brand/list',
      method: 'get',
      params
    })
  },
  carInfomodel(params) {//获取当前用户的默认车辆信息
    return request({
      url: '/vehicle/diy/mall/default/carInfo',
      method: 'get',
      params
    })
  },
  findList(params) {//车系数据列表
    return request({
      url: '/vehicle/config/brand/carSeries/findList',
      method: 'get',
      params
    })
  },
  chepailist(params) {//查询车辆列表
    return request({
      url: '/vehicle/diy/mall/car/list',
      method: 'get',
      params
    })
  },
  getGarageList(params) {//获取维修厂门店列表
    return request({
      url: '/vehicle/diy/mall/getGarageList',
      method: 'get',
      params
    })
  },
  getGarageInfo(params) {//获取维修厂门店信息
    return request({
      url: '/vehicle/diy/mall/getGarageInfo',
      method: 'get',
      params
    })
  },


  audit(params) {//查看认证信息
    return request({
      url: '/vehicle/diy/mall/car/audit',
      method: 'get',
      params
    })
  },
  msgCode(params) {//获取短信验证码
    return request({
      url: '/vehicle/diy/mall/msgCode',
      method: 'get',
      params
    })
  },
  carInfochexin(data) {//保存车型信息
    return request({
      url: '/vehicle/diy/mall/save/carInfo',
      method: 'post',
      data
    })
  },
  register(params) {//领取新注册的优惠券
    return request({
      url: '/vehicle/coupon/get/register',
      method: 'post',
      params
    })
  },

  info(data) {//修改用户信息
    return request({
      url: '/vehicle/diy/mall/update/User/info',
      method: 'post',
      data
    })
  },
  biangentires(params) {//变更轮胎规格
    return request({
      url: '/vehicle/diy/mall/change/tires',
      method: 'post',
      params
    })
  },

  chexindelete(params) {//删除车型资料
    return request({
      url: `/vehicle/diy/mall/delete/car/${params.id}`,
      method: 'delete',
      params
    })
  },
  Default(params) {//设为默认车型
    return request({
      url: `/vehicle/diy/mall/update/Default/${params.id}`,
      method: 'put',
      params
    })
  },
  uploadPicture(params) {//上传图片
    return request({
      url: '/vehicle/file/wx/uploadPicture',
      method: 'post',
      params
    })
  },
  uploadPicturexin(data) {//上传图片
    return request({
      url: '/vehicle/file/wx/uploadPicture2',
      method: 'post',
      data
    })
  },
  uploadPicturedan(data) {//单文件上传
    return request({
      url: '/vehicle/file/uploadPicture',
      method: 'post',
      data
    })
  },
  uploadurl(data) {//保存合同签名图
    return request({
      url: '/vehicle/diy/mall/sign/url',
      method: 'post',
      data
    })
  },
  carInfo(params) {//提交认证信息
    return request({
      url: '/vehicle/diy/mall/update/carInfo',
      method: 'post',
      params
    })
  },
  xiadanorder(data) {//下订单
    return request({
      url: '/vehicle/diy/mall/save/order',
      method: 'post',
      data
    })
  },
  penqiorder(data) {//喷漆下订单
    return request({
      url: '/vehicle/diy/mall/painting/order',
      method: 'post',
      data
    })
  },
  pay(params) {//线上支付
    return request({
      url: '/vehicle/diy/mall/pay',
      method: 'get',
      params
    })
  },
  chuzhipay(data) {//储值卡支付
    return request({
      url: '/vehicle/diy/mall/ms/pay',
      method: 'POST',
      data
    })
  },
  miaoshalist(params) {//秒杀列表
    return request({
      url: '/vehicle/diy/mall/seckill/list',
      method: 'get',
      params
    })
  },
  partner(params) {//检查是否购买合伙人活动
    return request({
      url: '/vehicle/diy/mall/check/partner',
      method: 'get',
      params
    })
  },
  seckillOrder(params) {//查询秒杀下单情况
    return request({
      url: '/vehicle/diy/mall/query/seckillOrder',
      method: 'get',
      params
    })
  },
  findByCarNo(params) {//已办理套餐
    return request({
      url: '/vehicle/customer/combo/findByCarNo',
      method: 'get',
      params
    })
  },
  findByPhone(params) {//根据车主电话号查询所有套餐
    return request({
      url: '/vehicle/customer/combo/findByPhone',
      method: 'get',
      params
    })
  },
  findRecordByComboCustomerId(params) {//已办理套餐详情
    return request({
      url: '/vehicle/customer/combo/findRecordByComboCustomerId',
      method: 'get',
      params
    })
  },
  infolunbo(params) {//已办理套餐详情
    return request({
      url: '/vehicle/diy/mall/get/info',
      method: 'get',
      params
    })
  },
  limit(params) {//查询是否有可领取的限时优惠券
    return request({
      url: '/vehicle/coupon/have/limit',
      method: 'get',
      params
    })
  },
  satisfy(data) {//查询符合要求的优惠券
    return request({
      url: '/vehicle/coupon/satisfy',
      method: 'POST',
      data
    })
  },
  yanzhencarNo(params) {//校验用户手机号
    return request({
      url: '/vehicle/customer/info/check/carNo',
      method: 'get',
      params
    })
  },
  jiyouoil(params) {//添加机油1L或4L
    return request({
      url: '/vehicle/diy/mall/add/oil',
      method: 'get',
      params
    })
  },

  peiqilist(params) {//喷漆列表-app
    return request({
      url: '/vehicle/diy/mall/painting/list/new',
      method: 'get',
      params
    })
  },
  peiqiprice(params) {//喷漆价格
    return request({
      url: '/vehicle/diy/mall/painting/price',
      method: 'get',
      params
    })
  },
  jinpinlist(params) {//查询精致车品列表
    return request({
      url: '/vehicle/diy/mall/periphery/list',
      method: 'get',
      params
    })
  },
  jinpindetails(params) {//查询精致车品商品详情
    return request({
      url: '/vehicle/diy/mall/periphery/details/app',
      method: 'get',
      params
    })
  },
  liansuolist(params) {//获取连锁门店列表
    return request({
      url: '/vehicle/diy/mall/chain/list',
      method: 'get',
      params
    })
  },
  qderror(params) {//保存前端错误
    return request({
      url: '/vehicle/diy/mall/front/error',
      method: 'post',
      params
    })
  },
  garagelists(params) {//查询可以添加的维修厂列表
    return request({
      url: '/vehicle/diy/mall/garage/list',
      method: 'get',
      params
    })
  },
  tuokema(params) {//拓客绑定上线二维码
    return request({
      url: '/vehicle/wxapi/qr/toker',
      method: 'get',
      params
    })
  },
  tuokelonger(params) {//我的下线列表
    return request({
      url: '/vehicle/toker/offline/list',
      method: 'get',
      params
    })
  },
  tuokelonger2(params) {//我的下线列表2
    return request({
      url: '/vehicle/toker/offline/list2',
      method: 'get',
      params
    })
  },
  adddefault(params) {//添加在线服务维修厂
    return request({
      url: '/vehicle/diy/mall/add/default',
      method: 'post',
      params
    })
  },
  changedefault(params) {//更换默认维修厂
    return request({
      url: '/vehicle/diy/mall/change/default',
      method: 'post',
      params
    })
  },
  unamelist(params) {//获取用户添加的维修厂列表
    return request({
      url: '/vehicle/diy/mall/user/garage/list',
      method: 'get',
      params
    })
  },
  deletegarage(params) {//删除在线服务维修厂
    return request({
      url: '/vehicle/diy/mall/delete/garage',
      method: 'DELETE',
      params
    })
  },
  checkadd(params) {//判断是否具有车辆信息
    return request({
      url: '/vehicle/diy/mall/check/add',
      method: 'get',
      params
    })
  },
  // ///////

  findItemByComboCustomerId(params) {
    return request({
      url: '/vehicle/customer/combo/findItemByComboCustomerId',
      method: 'get',
      params
    })
  },

  editCarOwnerInfo(data) {
    return request({
      url: '/vehicle/org/employee/editCarOwnerInfo',
      method: 'post',
      data
    })
  },
  queryGarageInfo(params) {//查询所有维修厂
    return request({
      url: '/vehicle/garage/queryGarageInfo',
      method: 'get',
      params
    })
  },
  apply(data) {//拓客申请
    return request({
      url: '/vehicle/toker/apply',
      method: 'post',
      data
    })
  },
  applyList(params) {//用户申请拓客历史列表
    return request({
      url: '/vehicle/toker/applyList',
      method: 'get',
      params
    })
  },
  getResult(params) {//用户申请拓客历史列表
    return request({
      url: '/vehicle/toker/getResult',
      method: 'get',
      params
    })
  },
  recommended(data) {//拓客用户推荐用户
    return request({
      url: '/vehicle/toker/recommended',
      method: 'post',
      data
    })
  },

  inCome(params) {//拓客车辆维修记录
    return request({
      url: '/vehicle/toker/inCome',
      method: 'get',
      params
    })
  },
  garageList(params) {//我的拓客厂
    return request({
      url: '/vehicle/toker/garageList',
      method: 'get',
      params
    })
  },
  lianjiegarageList(params) {//有连接分红的维修厂
    return request({
      url: '/vehicle/share/red/garageList',
      method: 'get',
      params
    })
  },
  report(params) {//个人连接分红统计
    return request({
      url: '/vehicle/share/red/user/report',
      method: 'get',
      params
    })
  },
  headline(params) {//获取去头条和模块图
    return request({
      url: '/vehicle/diy/mall/get/headline',
      method: 'get',
      params
    })
  },
  tokerdelete(params) {//删除车型资料
    return request({
      url: '/vehicle/toker/delete/off',
      method: 'delete',
      params
    })
  },
  getResultnew(params) {//公众号查询提成设置
    return request({
      url: '/vehicle/toker/getResult/new',
      method: 'get',
      params
    })
  },
  updatePercentagenew(data) {//公众号拓客提成修改
    return request({
      url: '/vehicle/toker/updatePercentage/new',
      method: 'post',
      data
    })
  },
  changegarage(params) {//变更套餐门店
    return request({
      url: '/vehicle/customer/combo/change/garage',
      method: 'post',
      params
    })
  },
  couponchangegarage(params) {//优惠券变更门店
    return request({
      url: `/vehicle/coupon/change/garage/${params.ogid}/${params.gid}`,
      method: 'post',
      params
    })
  },
  balancegid(params) {//储值卡券指定门店
    return request({
      url: '/vehicle/coupon/save/balance/gid',
      method: 'post',
      params
    })
  },
}
export default user
